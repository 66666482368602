.dropdown-container {
    flex: 1; /* Grow to fill available space */
    margin-right: 10px; /* Add some spacing between elements */
    max-width: 137px;
}

.div-same-line {
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically center elements */
    margin-bottom: 10px; /* Add some spacing between elements */
}

.div-same-line-dropdown {
  display: flex; /* Use flexbox */
  align-items: center; /* Vertically center elements */
  margin-bottom: 10px; /* Add some spacing between elements */
}

body {
    font-family: Arial, sans-serif;
    background-color: #DFEFFE;
    margin: 30px;
    padding: 0;
  }

  .main-link {
    font-size: 1rem;
    color: white;
  }

  .main-link:hover {
    color: white
  }

  .main-link:visited {
    color: white;
  }

  .main-link:active {
    color: white;
  }
  
  /* .main-div {
    width: 480px;
    background-color: #356BB2;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */

  .main-div {
    max-width: 480px;
    margin: 0 auto; 
  }

  .main-div-large {
    max-width: 716px;
    margin: 0 auto; 
  }

  .blue-box {
    background-color: #356BB2;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .gray-box {
    background-color: rgb(243, 243, 243);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .main-div-auto {
    width: 1000px; 
    background-color: #356BB2;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  a {
    font-size: 1.10rem;
    color: #1F4C84;
  }

  .bottom-align-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align items to the bottom */
    height: 200; /* Set a height to fill the viewport */
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
  }

  .center {
    text-align: center;
  }
  
  .form-actions > div {
    text-align: left;
  }
  
  .form-actions > div:last-child {
    text-align: right;
  }

  .div-right {
    text-align: right;
  }

  .instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
    width: auto;
  }
  
  .valid {
    color: limegreen;
    margin-left: 0.25rem;
  }
  
  .invalid {
    color: red;
    margin-left: 0.25rem;
  }
  
  .offscreen {
    position: absolute;
    left: -9999px;
  }
  
  .hide {
    display: none;
  }
    
  .control-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-control {
    margin-bottom: 20px;
    background-color: #356BB2;
    border: 0;
    padding: 0;
  }

  .textarea-control {
    margin-bottom: 20px;
    border: 1;
    padding: 0;
  }

  .form-file {
    margin-bottom: 20px;
    background-color: #356BB2;
    border: 0;
    padding: 0;
  }
  
  label {
    color: black;
    margin-bottom: 8px;
    display: block;
  }

  .label-white {
    color: white;
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  
  input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input[type="file"] {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    color: #333;
  }
  
  button {
    padding: 6px 12px;
    font-size: 16px;
    cursor: pointer;
    background-color: #172744;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

.dropdown-table {
    width: 100%;
    border-collapse: collapse; 
  }

.dropdown-control {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.checkbox-container {
    display: flex; /* Use flexbox to align elements horizontally */
    align-items: left; /* Align items vertically in the center */
  }

  .large-checkbox {
    width: 20px; /* Adjust the width to make the checkbox larger */
    height: 20px; /* Adjust the height to make the checkbox larger */
    margin-right: 10px; /* Optional: add spacing between the checkbox and label */
  }

  .list-table {
    width: 100%;
    border-collapse: collapse;
}

/* Apply styles to table headers */
.list-table th {
    background-color: #f2f2f2;
    text-align: left;
}

/* Apply styles to table cells */
.list-table td {
    border: 1px solid #ddd;
    padding: 0; /* Remove padding from cells */
    text-align: left;
    margin: 10px; /* Add margin between cells */
}

.checkbox-container {
  display: flex;
  align-items: center; /* Vertically align items */
}

.checkbox-container label {
  margin-right: 10px; /* Add spacing between labels */
}

.dark-blue {
  color: #1F4C84;
}