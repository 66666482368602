body {
    background-color: #ffffff;
    margin: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-family: Arial, sans-serif;
    color: #333333;
    padding: 10px;
}

h1 {
    color: #0066cc;
}

section {
    margin-bottom: 20px;
}

h2 {
    color: #0066cc;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    margin-bottom: 10px;
}

a {
    color: #0066cc;
    text-decoration: underline;
}

a:hover {
    color: #004080;
}

footer {
    margin-top: 20px;
    font-size: 0.8em;
    color: #666666;
}